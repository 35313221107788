import { useEffect } from "react";

import initService from "@/services/InitService";
import loadingScreenService from "@/services/LoadingScreenService";
import spaceService from "@/services/SpaceService";

const Home = () => {
  const isInitialized = initService((state) => state.isInitialized);

  useEffect(() => {
    if (!isInitialized) return;
    loadingScreenService.getState().pageReady();

    spaceService.getState().leaveSpace();
  }, [isInitialized]);

  return null;
};

Home.enableMap = true;
export default Home;
